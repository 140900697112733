import React from 'react'
import cx from 'classnames'

import Scrollbar from 'react-scrollbars-custom'

import { ProductCard } from 'src/components/product/card'
import { ProductInt } from 'src/interfaces/product'


export interface ProductScrollProps {
  data: {
    title?: string
    products?: [ProductInt]
  }
}

export const ProductScroll = ({ data }: ProductScrollProps) => {
  const {
    title,
    products
  } = data

  return (
    <React.Fragment>
      <div className='js-filter-hide psy z2 collection__grid-header  hide__filtering col c14'>
        <div className='p1 pl3--800 pr3--800 top bg--white df jcb aic'>
          <h3 className='m0 p0'>{title}</h3>
        </div>
      </div>
      <div className='bl1--800 collection__scroll hide--800 x bg--off-white pr z1'>
        <div className='ws--nowrap  js-filter-hide x  c14 df product__rows ofy--scroll'>
          {products && products.map((item, i) => (
            <div className='col product__card-col no-after bl c5 c4--800' key={`${item.product._id}${i}`}>
              <ProductCard {...item.product} />
            </div>
          ))}
        </div>
      </div>
      <div className='bl1--800 collection__scroll show--800 x'>
        <Scrollbar style={{ width: '100%', height: 550 }} noScrollY={true}>
          <div className='ws--nowrap  js-filter-hide x  c14 df product__rows'>
            {products && products.map((item, i) => (
              <div className='col product__card-col no-after bl c4 c4--800' key={`${item.product._id}${i}`}>
                <ProductCard {...item.product} />
              </div>
            ))}
          </div>
        </Scrollbar>
      </div>
    </React.Fragment>
  )
}

export default ProductScroll